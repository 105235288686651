const dataFaq = [
    {
        id: 1,
        title: 'What Should Be Listed On A Business Card?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
    },
    {
        id: 2,
        title: 'What Is The Standard Size Of Business Cards?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
        show: 'show'
    },
    {
        id: 3,
        title: 'What Should Be Listed On A Business Card?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
    },
    {
        id: 4,
        title: 'How Do I Design My Own Business Cards?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
    },
    {
        id: 5,
        title: 'Design My Own Business Cards?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
    },
    {
        id: 6,
        title: 'What Should Be Listed On A Business Card?',
        content: 'Nullam pulvinar, enim ornare tristique vehicula, massa est eleifend velit, vitae laoreet leo diam vitae quam. Curabitur dignissim nisi sed libero pellentesque hendrerit. Morbi condimen in elit in lobortis. Mauris nulla interdum quam dignissim congue vitae mattis felis.',
    },
    {
        id: 7,
        title: 'How Do I Design My Own Business Cards?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 8,
        title: 'Design My Own Business Cards?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 9,
        title: 'What Should Be Listed On A Business Card?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 10,
        title: 'How Do I Design My Own Business Cards?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
    {
        id: 11,
        title: 'Design My Own Business Cards?',
        content: 'We try to keep returns and exchanges as simple as possible. As long as they meet our criteria, it should be no problem at all to return or exchange them.',
    },
]

export default dataFaq;